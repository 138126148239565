jQuery('document').ready(function ($) {
	// Hynos MP3 player
	var song = new Audio(templateUrl+'/img/hynos.mp3');
	var	pause = true;
	var	duration = song.duration;
	var firstStarted = true;

	// $('.progressbar::-webkit-progress-bar').css('background-image', templateUrl+'/img/icon-player-progress.png');

	if (song.canPlayType('audio/mpeg;')) {
	  	song.type= 'audio/mpeg';
	  	song.src= templateUrl+'/img/hynos.mp3';
	} else {
  		song.type= 'audio/ogg';
  		song.src= templateUrl+'/img/hynos.ogg';
	}
	
	

	// ProgressBar
	// song.addEventListener('timeupdate',function (){
	// 	curtime = parseInt(song.currentTime, 10);
	// 	$('.progressbar').attr("value", curtime);
	// 	console.log(curtime);
	// });

	// $('.play').on('click', function() {
	// 	console.log('1st button pressed');
	// 	if(pause) {
	// 		console.log('play');
	// 		console.log(song.duration);
	// 		song.play();	
	// 		$('.play').attr('src', templateUrl+'/img/icon-pause.png');
	// 		pause = false;
	// 		if (firstStarted) {
	// 			$('.progressbar').attr('max', song.duration);
	// 			console.log(song.duration);
	// 			firstStarted = false;
	// 		}
	// 	} else {
	// 		console.log('pause');
	// 		$('.play').attr('src', templateUrl+'/img/icon-play.png');
	// 		$('.progress-icon').css('left', '0');
	// 		song.pause();
	// 		pause = true;
	// 	}
		
	// });

	// $('.stop').on('click', function() {
	// 	song.pause();
	// 	song.currentTime = 0;
	// 	$('.play').attr('src', templateUrl+'/img/icon-play.png');
	// 	pause = true;
	// });

	// End ProgressBar


	// Custom ProgressBar

	var playerWidth = $('.progress').width();
	console.log(playerWidth);
	var position = 0;
	song.addEventListener('timeupdate',function (){
		curtime = parseInt(song.currentTime, 10);
		console.log('curtime: ' + curtime);
		console.log('duration: ' + song.duration);
		console.log('playerWidth: ' + playerWidth);
		position = (curtime * playerWidth) / song.duration;
		console.log('position: ' + position);
		$('.progress-icon').css('left', position);
	});

	$('.play').on('click', function() {
		console.log('1st button pressed');
		if(pause) {
			console.log('play');
			console.log(song.duration);
			song.play();	
			$('.play').attr('src', templateUrl+'/img/icon-pause.png');
			pause = false;
		} else {
			console.log('pause');
			$('.play').attr('src', templateUrl+'/img/icon-play.png');
			$('.progress-icon').css('left', '0');
			song.pause();
			pause = true;
		}
		
	});

	$('.stop').on('click', function() {
		song.pause();
		song.currentTime = 0;
		$('.play').attr('src', templateUrl+'/img/icon-play.png');
		pause = true;
	});

	// End Custom ProgressBar
});