jQuery('document').ready(function ($) {

	var sliders = [];

	$('.swiper-container').each(function(index, element){

	    $(this).addClass('s'+index);
	    var slider = new Swiper('.s'+index, {
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			pagination: '.swiper-pagination',
			paginationClickable: true,
			// Disable preloading of all images
			preloadImages: false,
			// Enable lazy loading
			lazyLoading: true
		});
	    sliders.push(slider);
	    $(this).css('display', 'none');

	});

});