var closeHeader = function() {
	if($isHome) {
		$isHeaderClosed = true;
		// console.log('isHeaderClosed: '+$isHeaderClosed);
		// console.log('close');

		if(!$('.header').hasClass('smaller')) {
			$('.header').addClass('smaller');
			setTimeout(function() {
					$('.header .header__title a img').attr('src', templateUrl+'/img/logo-small.svg');
			}, 300);
		}
	}
}
var openHeader = function() {
	if($isHome) {
		$isHeaderClosed = false;
		// console.log('isHeaderClosed: '+$isHeaderClosed);
		// console.log('open');

		if($('.header').hasClass('smaller')) {
			$('.header').removeClass('smaller');
			$('.header .header__title a img').attr('src', templateUrl+'/img/logo.svg');
		}
	}
}
var toggleHeader = function() {
	if($isHome) {
		if ($(window).scrollTop() > $closeHeader && !$isHeaderClosed) {
			closeHeader();
		} else if ($(window).scrollTop() < $closeHeader && $isHeaderClosed){
			openHeader();
		}
	}
}

var openBox = function(element) {
	console.log('clock');
	if(element != '') {
		var box = element;	
		var boxImage = box+'_button';
	} else {
		var box = '.'+$(this).data('box');
		var boxImage = '.'+$(this).data('box')+'_button';
	}
	console.log(box);
	$(box).toggleClass("box_container_closed");
	var boxContainer = $(box).closest("div.box");
	console.log(boxContainer);

	// Marchas & Templos boxes
	if(boxContainer.hasClass("box_evento")) {
		var icon_closed = '/img/icon-fleche-closed-down-dark.svg';
		var icon_opened = '/img/icon-fleche-square-dark.svg';
		var swiper = boxContainer.find('.swiper-container');
		var contentBlock = boxContainer.find(".evento-item__content");
		var contentBlockFull = boxContainer.find(".evento-item__content_full");
		var contentBlockFullSmall = boxContainer.find(".evento-item__content_full_smallscreen");
		if(boxContainer.hasClass("box_closed")) {
			boxContainer.removeClass("box_closed");
            boxContainer.find('.box_button_foto').css('display', 'none');
			contentBlock.css('display', 'none');
			swiper.css('visibility', 'visible');
			swiper.css('display', 'block');
			var offsetSmall, offsetBig, content_full;
			if($(window).width() < 768) {
				offsetSmall = 120;
				offsetBig = 180;
				contentBlockFullSmall.css('display', 'block');
				content_full = boxContainer.find(".evento-item__content_full_smallscreen").height();
			} else {
				offsetSmall = 20;
				offsetBig = 80;
				contentBlockFull.css('display', 'block');
				content_full = boxContainer.find(".evento-item__content_full").height();
			}

			if(swiper.length != 0) {
				boxContainer.height(content_full + boxContainer.find(".swiper-container").height() + offsetBig);	
			} else {
				boxContainer.height(content_full + offsetSmall);	
			}

			
			$(boxImage).attr('src', templateUrl+icon_opened);
			// boxContainer.height($('.box1').height() + 40);
		} else {
			boxContainer.addClass("box_closed");
            boxContainer.find('.box_button_foto').css('display', 'block');
			setTimeout(function() {
				contentBlockFull.css('display', 'none');
				contentBlock.css('display', 'block');
				swiper.css('visibility', 'hidden');
				swiper.css('display', 'none');
			}, 500);
			
			$(boxImage).attr('src', templateUrl+icon_closed);
		}
	} else if(boxContainer.hasClass("header__mobile-menu")) { // Header boxe
		var icon_closed = '/img/icon-burger.svg';
		var icon_opened = '/img/icon-fleche-square-dark.svg';
		if(boxContainer.hasClass("box_closed")) {
			boxContainer.removeClass("box_closed");
			if($(window).width() <= 768) {
				boxContainer.height(390);		
			} else {
				boxContainer.height(210);		
			}
			$('.header__burger img').attr('src', templateUrl+icon_opened);
			// boxContainer.height($('.box1').height() + 40);
		} else {
			boxContainer.addClass("box_closed");
			$('.header__burger img').attr('src', templateUrl+icon_closed);
		}
	} else { // FAQ boxes
		var icon_closed = '/img/icon-fleche-closed.png';
		var icon_opened = '/img/icon-fleche-square.png';
		if(boxContainer.hasClass("box_closed")) {
			boxContainer.removeClass("box_closed");
			boxContainer.height(boxContainer.find(">:first-child").height() + 40);	
			$(boxImage).attr('src', templateUrl+icon_opened);
			// boxContainer.height($('.box1').height() + 40);
		} else {
			boxContainer.addClass("box_closed");
			$(boxImage).attr('src', templateUrl+icon_closed);
		}
	}

	
	// setTimeout(function() {
	// 	$('.box').css({'display': 'none'})
	// }, 500);
}

var structureHome = function() {
	// console.log('structureHome');
	if($isHome) {
		if($(window).width() <= 1024 && $(window).width() > 1010) {
			// console.log('structureHome - iPad');
			$('.bem-vindo').removeClass('col60').removeClass('col100').addClass('col50');
			$('.bem-vindo__faq_left').removeClass('col30').addClass('col100');
			$('.bem-vindo__faq_right').removeClass('col70').addClass('col100');
			$('.novidades').removeClass('col40').removeClass('col100').addClass('col50');
			$('.events').removeClass('col80').addClass('col100');
			$('.events__container .col50_left').css({'width': '50%', 'padding-right': '20px'});
			$('.events__container .col50_right').css({'width': '50%', 'padding-left': '20px'});
			$('.events__container img').css('height', '140px');
			$('.calendar_container').removeClass('col140').addClass('col100');
			$('.manifesto__content_items').removeClass('col3').addClass('manifesto_content_items_large');
		} else if($(window).width() <= 1010) {
			$('.bem-vindo').removeClass('col50').removeClass('col60').addClass('col100');
			$('.bem-vindo__faq_left').removeClass('col30').addClass('col100');
			$('.bem-vindo__faq_right').removeClass('col70').addClass('col100');
			$('.novidades').removeClass('col50').removeClass('col40').addClass('col100');
			$('.events').removeClass('col80').addClass('col100');
			$('.events__container .col50_left').css({'width': '100%', 'padding-right': '0px'});
			$('.events__container').find('.col50_right:first-child').css({'width': '100%', 'padding-left': '0px'});
			$('.events__container img').css('height', 'auto');
			$('.calendar_container').removeClass('col140').addClass('col100');
			$('.manifesto__content_items').removeClass('col3').addClass('manifesto_content_items_large');
		} else {
			// console.log('structureHome - desktop');
			$('.bem-vindo').removeClass('col50').removeClass('col100').addClass('col60');
			$('.bem-vindo__faq_left').removeClass('col100').addClass('col30');
			$('.bem-vindo__faq_right').removeClass('col100').addClass('col70');
			$('.novidades').removeClass('col50').removeClass('col100').addClass('col40');
			$('.events').removeClass('col100').addClass('col80');
			$('.events__container .col50_left').css({'width': '50%', 'padding-right': '20px'});
			$('.events__container .col50_right').css({'width': '50%', 'padding-left': '20px'});
			$('.events__container img').css('height', '140px');
			$('.calendar_container').removeClass('col100').addClass('col140');
			$('.manifesto__content_items').removeClass('manifesto_content_items_large').addClass('col3');

		}
	}
};

var fixeFooter = function () {
    var pageHeight = $('.page-container').height() + $('.header').height() + 320;
    if($(window).height() >= pageHeight) {
        console.log('pageHeight: ' + pageHeight);
        console.log('windowHeight: ' + $(window).height());
        $('.footer').addClass('fixed');
    } else {
        $('.footer').removeClass('fixed');
    }
};

var resizeMarchas = function () {
    if($('.page-evento').length > 0) {
        $('.box_evento').each( function() {
            if(!$(this).hasClass('box_closed')) {
                // console.log('box_container: ' + $(this).find('.box_container').height());
                // console.log('swiper_container: ' + $(this).find('.swiper-container').height());
                $(this).height($(this).find('.box_container').height() + $(this).find('.swiper-container').height() + 60);
                console.log('resize');
            }
        });
    }
};

jQuery(document).ready(function ($) {

	$closeHeader = 100;
	$isHeaderClosed = $('.header').hasClass('smaller');
	$isHome = $('.contentWrapper').hasClass('home');

	if(!$isHome) {
		$('.wrapper').addClass('wrapper__margin_small');
		$('.wrapper').removeClass('wrapper__margin_big');
	} else {
		$('.wrapper').addClass('wrapper__margin_big');
		$('.wrapper').removeClass('wrapper__margin_small');
		$('.wrapper').addClass('transition');
	}



	$('.box').each(function() {
		$(this).height($(this).find(">:first-child").height() + 40);
	});
	// $('.box:has(.box1)').height($('.box1').height() + 40);

	if($(window).width() <= 768) {
		$('.wrapper').addClass('wrapper__margin_small');
		$('.wrapper').removeClass('wrapper__margin_big');
		$('.faq_container .box_closed').removeClass('box_closed');
		$('.faq_container .box').css('height', 'auto');
		closeHeader();
		if($(window).width() < 768) {
			$('.evento-item__content_full_smallscreen').css('display', 'block');
			$('.evento-item__content_full').css('display', 'none');
		}
		
	} else if($(window).width() <= 1024) {
		if($isHome) {
			$('.wrapper').addClass('wrapper__margin_big');
			$('.wrapper').removeClass('wrapper__margin_small');
			if($(window).width() > 768) {
				$('.events').removeClass('col80').addClass('col100');
				$('.valendar').removeClass('col140').addClass('col100');
				$('.bem-vindo').removeClass('col60').addClass('col50');
				$('.novidade').removeClass('col40').addClass('col50');
			}
		} else {
			$('.wrapper').addClass('wrapper__margin_small');
			$('.wrapper').removeClass('wrapper__margin_big');
		}
	}
	else {
        $('.bem-vindo__faq_left').height($('.bem-vindo__faq_right').height());
    }
	structureHome();
    resizeMarchas();
    if(!$isHome && $('.culto').length > 0 || !$isHome && $('.manifesto').length > 0) {
        fixeFooter();
    }

	$(window).on('resize', function (e) {	
		$(this).height($(this).find(">:first-child").height() + 40);	
		var height = $('.manifesto__video iframe').width() * 0.5628205128205128;
		$('.manifesto__video iframe').height(height);
		structureHome();
        if(!$isHome && $('.culto').length > 0 || !$isHome && $('.manifesto').length > 0) {
            fixeFooter();
        }
		if($(window).width() <= 1024) {
			if($isHome) {
				$('.wrapper').addClass('wrapper__margin_big');
				$('.wrapper').removeClass('wrapper__margin_small');
			}
			if(!$('.header__mobile-menu').hasClass('box_closed')) {
				$('.header__mobile-menu').height(180);
			}

			if($(window).width() <= 768) {
				closeHeader();
				$('.wrapper').addClass('wrapper__margin_small');
				$('.wrapper').removeClass('wrapper__margin_big');
				if(!$('.header__mobile-menu').hasClass('box_closed')) {
					$('.header__mobile-menu').height(340);
				}
				$('.faq_container .box_closed').removeClass('box_closed');
				$('.faq_container .box').css('height', 'auto');
			} else {
				if ($(window).scrollTop() < $closeHeader && $isHeaderClosed){
					openHeader();
				}
				$('.faq_container .box').addClass('box_closed');
				$('.faq_container .box').css('height', '0px');
                $('.bem-vindo__faq_left').height($('.bem-vindo__faq_right').height());
			}
		} else {
			if($isHome) {
				$('.wrapper').addClass('wrapper__margin_big');
				$('.wrapper').removeClass('wrapper__margin_small');
                $('.bem-vindo__faq_left').height($('.bem-vindo__faq_right').height());
			} 
			if ($(window).scrollTop() < $closeHeader && $isHeaderClosed){
				openHeader();
			}
			if(!$('.header__mobile-menu').hasClass('box_closed')) {
				this.addClass('box_closed');
			}
		}
		resizeMarchas();
	});

	
	$(window).on('scroll', function (e) {
		if($(window).width() > 768) toggleHeader();
	});

	$('.elu-menu_items').on('click', function() {
		var link = '#' + $(this).data('link');
		var scroll = $(link).offset().top - 156;

		var body = $("html, body");
		body.stop().animate({scrollTop:scroll}, '500', 'swing');
		// body.stop().animate({scrollTop:0}, '500', 'swing', function() { 
		// 	alert("Finished animating");
		// });
	});

	

	$('.title_category_faq').on('click', function() {
		var box = '.'+$(this).data('box');
		console.log(box);
		if($(window).width() > 768) {
			openBox(box);
		} 
	})

	$('.header__burger').on('click', function() {
		var box = '.header__mobile-menu';
		console.log(box);
		openBox(box);
	})

	$('.evento-item__arrow').on('click', function() {
		var box = '.'+$(this).find('.box_button').data('box');
		console.log(box);
		openBox(box);
	});
	// Drawer boxes to show/hide content
	// $('.box_button').on('click', function() {
	// 	var box = '.'+$(this).data('box');
	// 	openBox(box)
	// });
	
	var url = window.location.href;
	console.log(url);
	var anchorName = document.location.hash.substring(1);
	if(anchorName.length != null){
		var scroll = $('#'+anchorName).offset().top - 156;

		var body = $("html, body");
		body.stop().animate({scrollTop:scroll}, '500', 'swing');
		console.log(anchorName);
		$('#'+anchorName).find('.box_button').click();
		setTimeout(function() {
			$('.swiper-container').css('display', 'block');
		}, 200);
		console.log($('.swiper-container'));
	}
	// if(pathname)

	function textFormat(string) {
		return string.replace(/<\s*\/\s*\w\s*.*?>|<\s*br\s*>/g,"<br>");
	}
	

	
	// $('.button').on('click', function() {
	// 	alert('Ma popup ! <br>Voilà mon text !');
	// });	


});
