jQuery(document).ready(function ($) {

	var showPopup = function(popupID) {
		$('.popup-container').hide();
		// $('#popup-'+popupID).css({'margin-top': $(window).scrollTop()});
		$('#popup-'+popupID).show();
		$('.background-black').show();
		$('body').addClass('noscroll');
	};

	var hidePopup = function() {
		$('.popup-container').hide();
		$('.background-black').hide();
		$('body').removeClass('noscroll');
	}

	$(".popup-link").on('click', function (e){
		var popupID = $(this).parents('.novidades__item').attr('data-popup');
		showPopup(popupID);
	});
	$('.inscrevase-popup').on('click', function(e) {
		var popupID = $(this).attr('data-popup');
		showPopup(popupID);
	});

	$('.popup__action a').on('click', function (e){
		hidePopup();
	});
	$('.popup-container').on('click', function (e) {
		if(!$(e.target).is('.popup') && !$(e.target).is($('.popup').find('*'))){
			hidePopup();
		}
	});
});