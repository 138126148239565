jQuery(document).ready(function ($) {

	//Calendar navigation
	var navigationOffset = 0;
	var numMonth = $('.calendar__events_container_item').length;
	var stepVal;
	var monthNbPerSlide;
	var calendarItemWidth;
	var last_CalendarItemWidth;

		console.log($('.calendar__events').width());
		
	if(($(window).width() < 1100 && $(window).width() > 1010) || ($(window).width() < 550)) {
		monthNbPerSlide = 1;
		calendarItemWidth = $('.calendar__events').width();
		$('.calendar__events_container_item').width(calendarItemWidth);

		last_CalendarItemWidth = $('.calendar__events').width();
	} else {
		monthNbPerSlide = 2;
		calendarItemWidth = $('.calendar__events').width() / 2;
		$('.calendar__events_container_item').width(calendarItemWidth);

		console.log($('.calendar__events').width());
	}
	stepVal = calendarItemWidth;

	var checkNavigation = function(){
		var windowWidth = $(window).width();
		
		stepVal = calendarItemWidth;
		
		if(navigationOffset == 0){
			$('.navigation.previous').addClass('inactive');
		}else{
			$('.navigation.previous').removeClass('inactive');
		}

		if(navigationOffset <= (numMonth-monthNbPerSlide) * -stepVal){
			$('.navigation.next').addClass('inactive');
		}else{
			$('.navigation.next').removeClass('inactive');
		}
	};
	$('.calendar__arrows-next').on('click', function(e){
		if(navigationOffset > (numMonth - monthNbPerSlide) * -stepVal){
			navigationOffset -= stepVal;
			$('.calendar__events_container').css({left:navigationOffset});
			checkNavigation();
			console.log('navigationOffset: ' + navigationOffset);
		}
	});
	$('.calendar__arrows-previous').on('click', function(e){
		if(navigationOffset < 0){
			console.log(navigationOffset);
			navigationOffset += stepVal;
			$('.calendar__events_container').css({left:navigationOffset});
			checkNavigation();
			console.log('navigationOffset: ' + navigationOffset);
		}
	});
	checkNavigation();
	
	$(window).on('resize', function (e){

		// Calendrier resize
		if(($(window).width() < 1100 && $(window).width() > 1010) || ($(window).width() < 550)) {
			calendarItemWidth = $('.calendar__events').width();	
			monthNbPerSlide = 1;
		} else {
			calendarItemWidth = $('.calendar__events').width() / 2;
			monthNbPerSlide = 2;
		}
		// console.log(calendarItemWidth);
		// console.log(last_CalendarItemWidth);
		$('.calendar__events_container_item').width(calendarItemWidth);

		var containerWidth = $('.calendar__events_container').css('left');

		if(navigationOffset != 0) {
			console.log('containerWidth: ' + containerWidth);
			var newPosition = ( last_CalendarItemWidth - calendarItemWidth );
			navigationOffset += newPosition;
			console.log('newPosition: ' + (navigationOffset - newPosition));
			console.log('navigationOffset: ' + navigationOffset);
			$('.calendar__events_container').css('left', navigationOffset - newPosition);
			stepVal = calendarItemWidth;
		} else {

			stepVal = calendarItemWidth;
		}

		last_CalendarItemWidth = calendarItemWidth;
		// if(navigationOffset > (numMonth-2) * -stepVal){
		// 	navigationOffset -= stepVal;
		// 	$('.calendar__events_container').css(left);
		// 	checkNavigation();
		// }
	});
});